<template>
    <ValidationObserver ref="validacionItem" tag="div" class="row mx-0 align-items-center pt-1 mt-3">
        <div class="col-5 px-0">
            <div class="d-flex">
                <div class="col pl-2 pr-0">
                    <ValidationProvider v-slot="{errors}" rules="required|min:3|max:20" name="identificador">
                        <el-input v-model="info.identificador" class="w-100 br-6" size="small" maxlength="21" :disabled="!foco" @change="volverOriginal" />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
                <div v-if="info.identificadores.length > 1" class="col-auto px-2">
                    <el-tooltip placement="bottom" content="Leecheros con el mismo codigo" effect="light">
                        <div class="btn-general border br-8 px-3 py-1 text-general cr-pointer" @click="verCodigo()">
                            {{ info.identificadores.length }} <i class="icon-leechero-cap text-general f-18" />
                        </div>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <div v-if="$can('botones_leecheros_identificadores')" class="col-auto px-2">
            <el-tooltip v-if="foco" class="item" effect="light" content="Guardar" placement="bottom">
                <div class="btn-action border cr-pointer d-middle-center br-8" @click="editar">
                    <i class="icon-ok-circled-outline f-20 text-general" />
                </div>
            </el-tooltip>
            <el-tooltip v-else class="item" effect="light" content="Editar" placement="bottom">
                <div class="btn-action border d-middle-center cr-pointer br-8" @click="foco=true">
                    <i class="icon-pencil-outline f-20 text-general" />
                </div>
            </el-tooltip>
        </div>
        <div v-if="$can('botones_leecheros_identificadores')" class="col-auto px-0">
            <el-tooltip class="item" effect="light" content="Eliminar" placement="bottom">
                <div class="btn-action d-middle-center border cr-pointer br-8" @click="eliminar">
                    <i class="icon-trash-empty text-general f-20" />
                </div>
            </el-tooltip>
        </div>
        <modalEliminar ref="modalEliminar" titulo="Eliminar identificador" mensaje="¿Desea eliminar el identificador?" @eliminar="deleteEtiqueta" />
        <modal-leechero-codigo ref="modalLeecheroCodigo" />
    </ValidationObserver>
</template>

<script>
import Produccion from "../../../../services/tiendas/produccion";

export default {
    components: {
        modalLeecheroCodigo: () => import('../partials/modalLeecherosCodigo')
    },
    props:{
        info:{
            type: Object,
            default: () => ({})
        }
    },
    data(){
        return{
            identificadorCopy: null,
            foco: false
        }
    },

    mounted(){
        this.identificadorCopy = this.info.identificador
    },
    methods: {
        async info_tienda(){
            try {
                const { data } = await Produccion.info_tienda(this.$route.params.id_tienda)
                this.tienda = data.info
                this.coordenadas.lat = this.tienda.latitud * 1
                this.coordenadas.lng = this.tienda.longitud * 1
            } catch (error){
                this.error_catch(error)
            }
        },

        volverOriginal(){
            // console.log('this.identificadorCopy', this.identificadorCopy);
            // this.delay(this.reset())
            // this.info.identificador = this.identificadorCopy

        },

        async editar(){
            try {
                const valid = await this.$refs.validacionItem.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                console.log('info.identificador', this.info.identificador);
                const enviar = {
                    ...this.info,
                    identificador: this.info.identificador
                }
                const {data} = await Produccion.editar_identificador(enviar)
                const newData = data.identificador
                this.$emit('editar', newData)
                this.foco= false

                this.notificacion('Mensaje', 'Identificador editado', 'success')

            } catch (e){
                this.error_catch(e)
            }

        },
        eliminar(){
            this.$refs.modalEliminar.toggle()
        },
        verCodigo(){
            this.$refs.modalLeecheroCodigo.toggle(this.info.identificadores);
        },
        async deleteEtiqueta(){
            try {
                const {data} = await Produccion.eliminar_identificador(this.info.id)
                this.notificacion('Mensaje', 'Identificador eliminado', 'success')
                this.$refs.modalEliminar.toggle()

                this.$emit('eliminar')
            } catch (e){
                this.error_catch(e)
            }

        }

    }
}
</script>

<style lang="css" >
.br-12{
    border-radius: 12px;
}
</style>
